import React, { useState, useEffect } from "react";
// import { Link } from "gatsby"
import { isLoggedIn, logout } from "../services/auth";

import { navigate } from "gatsby";

import Layout from "../layout";

import ClientsView from "../components/admin/clients";
import AfiliadosView from "../components/admin/afiliados";
import AuditoriasView from "../components/admin/auditorias";

import { 
    Box, 
    Container, 
    Grid, 
    Divider,
    MenuList, 
    MenuItem,
    ListItemText,
    ListItemIcon,
    Tab,
    Tabs
} from "@mui/material";

import LogoutIcon from '@mui/icons-material/Logout';
import PeopleIcon from '@mui/icons-material/People';
import PolylineIcon from '@mui/icons-material/Polyline';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
 
const Admin = () => {
    const [view, setView] = useState(0);
    const [openMenu, setOpenMenu] = useState(false);
    const [isLoggin, setIsLoggin] = useState(false)
    const menu = [
        "clientes",
        "afiliados"
    ]
    useEffect(() => {
        // console.log('admin page')
        if(!isLoggedIn()) {
            navigate('/login')
            setIsLoggin(false)
        } else {
            setIsLoggin(true)
        }
    }, [])

    const handleView = (newView) => {
        setView(newView)
    }

    const handleChange = (event, newValue) => {
        setView(newValue);
    };

    const handleLogout = () => {
         logout(() => {
            // console.log('logout')
            navigate('/login')
        });
    }

    const handleMenu = () => {
        setOpenMenu(!openMenu)
    }

    return (
        <Layout>
            {
                isLoggin &&
                <>
                    <Box
                        sx={{
                            display: { xs: 'block', md: 'none' },
                            background: '#ededed',
                            position: "sticky",
                            zIndex: 5,
                            top: 60
                        }}
                    >
                        <Tabs 
                            value={view} 
                            onChange={handleChange}
                            centered
                        >
                            <Tab label="Clientes" />
                            <Tab label="Afiliados" />
                        </Tabs>
                        <Divider />
                    </Box>
                    
                    <Container
                        maxWidth="none"
                    >
                        <Grid
                            container
                            spacing={3}
                            sx={{
                                paddingTop: 3
                            }}
                        >
                            <Grid
                                item
                                md={2}
                                sx={{
                                    display: { xs: 'none', md: 'block' },
                                    borderRight: '1px solid #ededed',
                                    background: "rgba(255,255,255,.2)",
                                    paddingLeft: "0 !important",
                                    position: "relative",
                                    minHeight: '100vh'
                                }}
                            >
                                <MenuList
                                    // component={Grid}
                                    sx={{
                                        position: 'sticky',
                                        top: 70
                                    }}
                                >
                                    <MenuItem
                                        onClick={() => handleView(0)}
                                        selected={view === 0}
                                    >
                                        <ListItemIcon>
                                            <PeopleIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Clientes</ListItemText>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => handleView(1)}
                                        selected={view === 1}
                                    >
                                        <ListItemIcon>
                                            <PolylineIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Afiliados</ListItemText>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => handleView(2)}
                                        selected={view === 2}
                                    >
                                        <ListItemIcon>
                                            <VpnKeyIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Auditorias</ListItemText>
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem
                                        onClick={handleLogout}
                                    >
                                        <ListItemIcon>
                                            <LogoutIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Salir</ListItemText>
                                    </MenuItem>
                                </MenuList>
                            </Grid>

                            {
                                view === 0
                                ? <ClientsView />
                                : view === 1
                                ? <AfiliadosView />
                                : view === 2
                                ? <AuditoriasView />
                                : null
                            }
                        </Grid>
                    </Container>
                </>
            }
        </Layout>
    ) 
}

export default Admin;
