import React, { useState, useEffect } from "react";

import moment, { locale } from 'moment';
import 'moment/locale/es'

import { getClients, getBySearch, postAuditoria } from "../../services/getClients";
import { getAffiliates } from "../../services/getAffilates";

import { Link } from "gatsby-theme-material-ui";

import Table from '../admin/table';

import {
    Box,
    Paper,
    Grid,
    Typography,
    IconButton,
    Button,
    Skeleton,
    TextField,
    Alert
} from "@mui/material";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Autocomplete from '@mui/material/Autocomplete';

import CachedIcon from '@mui/icons-material/Cached';

import {
    ContentPaste,
    // AvTimerIcon
    FilterAlt
} from '@mui/icons-material';

import AvTimerIcon from '@mui/icons-material/AvTimer';
import PortraitIcon from '@mui/icons-material/Portrait';

let formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
});

const AuditStatus = (props) => {
    const { row } = props;
    const [nip, setNip] = useState(row?.consultas[row?.consultas?.length - 1]?.authNip);
    const [auditar, setAuditar] = useState(null);

    const handleAuditoria = async (data) => {
        // console.log('Data: ', data)
        let response = await postAuditoria({ _id: data?._id })
        // console.log('Res: ', response)
        if (response?.folioCDC) {
            setNip(response?.folioCDC)
        } else {
            setAuditar(response)
        }
    }

    return (
        <>
            {
                nip
                    ? nip
                    : <Button onClick={() => handleAuditoria(row)} sx={{ textTransform: "capitalize" }} color="secondary" variant="text">Auditar</Button>
            }

            <Dialog open={auditar != null} onClose={() => { setAuditar(null) }}>
                <DialogTitle>Estatus</DialogTitle>
                <DialogContent>
                    {
                        auditar?.errores && auditar?.errores?.map(err => {
                            return (
                                <DialogContentText>
                                    {err?.mensaje}
                                </DialogContentText>
                            )
                        })
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAuditar(null)}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const ClientsView = () => {
    const [data, setData] = useState(null);
    const [affiliates, setAffiliates] = useState([])
    const [message, setMessage] = useState(null)
    const [metaData, setMetaData] = useState({
        total: 0,
        from: 0,
        size: 15,
        page: 0
    });
    const fields = [
        {
            id: "score",
            label: "Puntaje"
        },
        {
            id: "resultado",
            label: "Preautorizado"
        },
        {
            id: 'afiliado',
            label: 'Afiliado'
        },
        {
            id: "nombre",
            label: "Nombre(s)"
        },
        {
            id: "hubspot",
            label: "Hubspot"
        },
        {
            id: "nip",
            label: "NIP"
        },
        {
            id: "consultas",
            label: "Consultas"
        },
        {
            id: "fecha",
            label: "Fecha"
        }
    ]

    useEffect(() => {
        // console.log('change data', metaData)
        if (!data) {
            handleGetData(metaData)
            // console.log('get data', data)
            handleGetAffiliates()
        }
    }, [])

    // console.log(data)
    const createRow = async (data) => {
        // console.log(data)
        let rows = await data?.map((row, index) => {
            // console.log('row: ', row)
            return {
                // index: index + 1,
                puntaje: row?.consultas[row?.consultas?.length - 1]?.score?.valor,
                score: <Button component={Link} underline="none" color="inherit" to={`/reporte?id=${row?._id}`}><b>{row?.consultas[row?.consultas?.length - 1]?.score?.valor}</b></Button>,
                resultado: <Button component={Link} underline="none" color="inherit" to={`/resultado?id=${row?._id}`}>{row?.servicios?.preautorizado ? formatter.format(row?.servicios?.preautorizado) : "$0.00"}</Button>,
                nombre: `${row?.persona?.primerNombre} ${row?.persona?.segundoNombre} ${row?.persona?.apellidoPaterno} ${row?.persona?.apellidoMaterno}`,
                correo: row?.contacto?.correo,
                celular: `${row?.contacto?.codigoPais} ${row?.contacto?.celular}`,
                afiliado: row?.referencia,
                nip: handleButton(row),
                consultas: row?.consultas?.length,
                hubspot: row?.hubspotId,
                fecha: moment(row?.consultas[row?.consultas?.length - 1]?.fechaConsulta).format("DD/MMM/YYYY")
            }
        })
        return rows;
    }

    const handleButton = (row) => {
        return row?.consultas[row?.consultas?.length - 1]?.authNip || <AuditStatus row={row} />
    }

    const handleGetData = (newMetaData, isMerge = false) => {
        // console.log(newMetaData)
        let response = getClients(newMetaData);

        response
            .then(async res => {
                setData(null)
                // console.log("response ", res)
                let response = res?.content
                let rows = await createRow(response)
                if (isMerge) {
                    rows = data?.concat(rows)
                }
                setMetaData({ ...metaData, ...newMetaData, ...res?.metadata, query: null })
                setData(rows)
                // console.log(rows)
                setMessage(null)
            })
    }

    const handleSearch = (e) => {
        let search = metaData?.query;
        // console.log(search)
        if (search) {
            let response = getBySearch(search, metaData);
            response
                .then(async res => {
                    if (res?.content?.length > 0) {
                        setData(null)
                        // console.log(res)
                        let rows = await createRow(res?.content)
                        setData(rows)
                        setMetaData({ ...metaData, ...res?.metadata })
                        setMessage({ status: 200, message: `${res?.content?.length} resultados para: ${search}` })
                    } else {
                        setMessage({ status: 400, message: `No hubo resultados para: ${search}` })
                    }
                })
                .catch(err => {
                    setMessage({ status: 500, message: "Hubo un error en la consulta, vuelve a intentarlo." })
                })
        }
    }

    const handleQuerySearch = (e) => {
        let query = e?.target?.value;
        setMetaData({ ...metaData, query: query })
        setMessage(null)
    }

    const handlePaginator = (newMetaData) => {
        // console.log('New meta', newMetaData)
        // let metaDataFix = {...metaData, from: metaData?.size * metaData?.page, page: metaData?.page + 1}
        let metaDataFix = { ...metaData, ...newMetaData }
        // console.log('new meta', metaDataFix)
        if (metaData?.total >= data?.length) {
            handleGetData(metaDataFix, true)
        }
    }

    const handleGetAffiliates = async () => {
        let response = await getAffiliates()
        let newList = response?.content?.map(item => item?.code)
        // console.log('list', response)
        setAffiliates(newList)
    }

    return (
        <Grid
            container
            item
            spacing={2}
            pb={3}
            xs={12}
            md={10}
        >
            <Grid
                item
                xs={12}
                md={4}
            >
                <Paper
                    elevation={0}
                    variant="outlined"
                    sx={{
                        padding: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                >
                    <Box>
                        {
                            data
                                ?
                                <>
                                    <Typography variant="h5" component="span">{metaData?.total || 0}</Typography>
                                    <Typography variant="subtitle1" component="p">Registros totales</Typography>
                                </>
                                :
                                <>
                                    <Skeleton width={60} />
                                    <Skeleton width={150} />
                                </>
                        }
                    </Box>
                    <PortraitIcon fontSize="large" />
                </Paper>
            </Grid>

            <Grid
                item
                xs={6}
                md={4}
            >
                <Paper
                    elevation={0}
                    variant="outlined"
                    sx={{
                        padding: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                >
                    <Box>
                        {
                            data
                                ?
                                <>
                                    <Typography variant="h5" component="span">{data?.filter(row => row?.puntaje >= 550)?.length}</Typography>
                                    <Typography variant="subtitle1" component="p"> Puntaje mayor a 550</Typography>
                                </>
                                :
                                <>
                                    <Skeleton width={60} />
                                    <Skeleton width={150} />
                                </>
                        }
                    </Box>
                    <AvTimerIcon fontSize="large" />
                </Paper>
            </Grid>

            <Grid
                item
                xs={6}
                md={4}
            >
                <Paper
                    elevation={0}
                    variant="outlined"
                    sx={{
                        padding: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                >
                    <Box>
                        {
                            data
                                ?
                                <>
                                    <Typography variant="h5" component="span">{data?.filter(row => (typeof row?.nip) == 'number')?.length}</Typography>
                                    <Typography variant="subtitle1" component="p">Auditoria de firmas</Typography>
                                </>
                                :
                                <>
                                    <Skeleton width={60} />
                                    <Skeleton width={150} />
                                </>
                        }
                    </Box>
                    <ContentPaste fontSize="large" />
                </Paper>
            </Grid>

            <Grid
                xs={12}
                item
            >
                {
                    data
                        ?
                        <Box
                            sx={{
                                "& [name='red']": {
                                    color: 'red'
                                },
                                "& [name='green']": {
                                    color: 'green'
                                },
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2
                            }}
                        >
                            <Grid
                                spacing={1}
                                container
                            >
                                <Grid item xs={12} md={5}>
                                    <TextField
                                        label="Buscar por nombre, correo, celular o RFC."
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleQuerySearch}
                                        // helperText={error}
                                        // error={error != null}
                                        // defaultValue={metaData?.query}
                                        sx={{
                                            background: '#fff'
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={5}>
                                    <Autocomplete
                                        disabled={!affiliates}
                                        disablePortal
                                        options={affiliates}
                                        onSelect={handleQuerySearch}
                                        renderInput={(params) => <TextField {...params} label="Buscar por Afiliados" />}
                                        sx={{
                                            background: '#fff'
                                        }}
                                    />
                                </Grid>
                                <Grid item md={2}>
                                    <Button
                                        size="large"
                                        variant="contained"
                                        color="secondary"
                                        sx={{ color: '#fff', height: '100%', width: '100%' }}
                                        disabled={!metaData?.query}
                                        onClick={handleSearch}
                                        startIcon={<FilterAlt />}
                                    >Buscar</Button>
                                </Grid>
                                <Grid item>
                                    {
                                        message &&
                                        <Alert severity={message?.status == 200 ? "success" : message?.status == 500 ? "error" : 'warning'}>{message?.message}</Alert>
                                    }
                                </Grid>
                            </Grid>

                            <Table
                                title={`Resultados: ${data?.length}`}
                                list={data}
                                fields={fields}
                                paginator={handlePaginator}
                                metaData={metaData}
                                index={true}
                                responsiveFields={[]}
                                action={
                                    <IconButton
                                        onClick={() => handleGetData({ from: 0, size: 15, page: 0 })}
                                    ><CachedIcon /></IconButton>
                                }
                            />

                        </Box>
                        :
                        <Skeleton
                            height={300}
                            sx={{
                                transform: "none"
                            }}
                        />
                }
            </Grid>
        </Grid>
    )
}


export default ClientsView
