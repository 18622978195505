import React, { useState, useEffect } from "react";
// import { Link } from "gatsby"

import { 
    Paper,
    Button,
    Typography, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    TablePagination,
    Divider,
    Toolbar
} from "@mui/material";

const TableCustom = (props) => {
    const {
        list = [], 
        hiddenButton = false,
        title = "Lista", 
        action,
        rowAction,
        rowActionLabel,
        fields = [
            {
                id: "test",
                label: "test"
            }
        ],
        responsiveFields = [0, 1, 2],
        paginator,
        metaData,
        index = false
    } = props;
    const [data] = useState(list);
    const [page, setPage] = useState(metaData?.page);
    const [rowsPerPage, setRowsPerPage] = useState(metaData?.size);

    useEffect(() => {
        // console.log(page, rowsPerPage)
        // console.log(metaData)
        let newMetaData = {from: page * rowsPerPage, page: page}
        if(metaData?.page != page){
            paginator(newMetaData)
        }
    }, [page])

    const handleChangePage = (event, newPage) => {
        // console.log('page: ', newPage)
        // setPage(newPage);
        setPage(page + 1);
        // paginator({page: newPage})
        // let newMetaData = {from: page * rowsPerPage, page: page}

        // paginator(newMetaData)
    };

    const handleChangeRowsPerPage = (event) => {
        // console.log('rows', parseInt(event.target.value, 10))
        // setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);
        paginator({size: parseInt(event.target.value, 10)})
    };

    return(
        <>
            <Paper
                elevation={0}
                variant="outlined"
                sx={{
                    maxWidth: 'calc(100vw - 32px)'
                }}
            >
                <Toolbar
                    sx={{
                        pl: {
                            xs: 1.5
                        },
                        justifyContent: "space-between"
                    }}
                >
                    <Typography
                        variant="subtitle2"
                        component="h6"
                    >
                        { title }
                    </Typography>

                    {
                        action
                    }

                </Toolbar>

                <Divider />
                    
                <TableContainer>
                    <Table
                        sx={{
                            '& .MuiTableCell-root': {
                                whiteSpace: 'nowrap'
                            }
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                {
                                    index &&
                                    <TableCell key="id"></TableCell>
                                }
                                {
                                    rowAction &&
                                    <TableCell>{ rowActionLabel }</TableCell>
                                }
                                {
                                    fields?.map( (field, i) => {
                                        return(
                                            <TableCell sx={{display: responsiveFields?.length > 0 ? responsiveFields?.filter(x => x === i)?.length ? {xs: 'table-cell'} : {xs: 'none', md: 'table-cell'} : "table-cell"}} key={i}>{field?.label}</TableCell>
                                        )
                                    })
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data?.map( (row, i) => {
                                    return(
                                        <TableRow key={i}>
                                            {
                                                index &&
                                                <TableCell key="id">{i + 1}</TableCell>
                                            }
                                            {
                                                rowAction && 
                                                <TableCell>
                                                    {
                                                        rowAction(row, i)
                                                    }
                                                </TableCell>
                                            }
                                            {
                                                fields?.map( (cell, j) => {
                                                    return(
                                                        <TableCell sx={{display: responsiveFields?.length > 0 ? responsiveFields?.filter(x => x === j)?.length ? {xs: 'table-cell'} : {xs: 'none', md: 'table-cell'} : "table-cell"}} key={j}>{row[cell?.id]}</TableCell>
                                                    )
                                                })
                                            }
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            {
                hiddenButton
                ? null
                : <Button disabled={metaData?.total <= list?.length} onClick={handleChangePage}>Mostrar más</Button>
            }

            {/* <TablePagination
                rowsPerPageOptions={[15, 20, 30]}
                component="div"
                count={metaData?.total}
                rowsPerPage={metaData?.size}
                page={metaData?.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
        </>

    )
}

export default TableCustom
