import axios from "axios";

export const getAuditorias = async (size) => {
    
    let token = '123456';

    let config = {
      headers: {
        'x-access-token': token
      }
    }

    let response = await axios.get(
        `/api/v1/credito/auditorias?size=${size}`,
        config
    )

    let result = response?.data

    return result;
}