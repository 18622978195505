import React, { useState, useEffect } from "react";
// import { Link } from "gatsby"
import {getAffiliates, getBySearch, postAffiliates, putAffiliates, deleteAffiliates, getByCode} from "../../services/getAffilates";

import Table from "./table";

import { 
    Box,
    Button, 
    IconButton, 
    CircularProgress, 
    Grid,
    Skeleton,
    TextField,
    Typography,
    Switch,
    FormGroup,
    FormControl, 
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Alert,
    Tooltip
} from "@mui/material";

import {
    FilterAlt, SignalWifiStatusbarNullTwoTone
} from '@mui/icons-material';

import CachedIcon from '@mui/icons-material/Cached';
import EditIcon from '@mui/icons-material/Edit';
 
const Afiliados = () => {
    const [data, setData] = useState(null);
    const [list, setList] = useState(null);
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState();
    const [isDelete, setIsDelete] = useState(false);
    const [load, setLoad] = useState(false);
    const [helper, setHelper] = useState();
    const [message, setMessage] = useState(null)
    const [messageFilter, setMessageFilter] = useState(null);
    const [via, setVia] = useState('sms');
    const [country, setCountry] = useState(0);
    const [metaData, setMetaData] = useState({
        total: 0,
        from: 0,
        size: 10,
        page: 0
    });
    const fields = [
        {
            id: "edit",
            label: "Editar"
        },
        {
            id: "code",
            label: "Código"
        },
        {
            id: "name",
            label: "Nombre"
        },
        {
            id: "email",
            label: "Correo"
        },
        {
            id: "phone",
            label: "Celular"
        }
    ]
    const countryCodes = [
        {
            sms: '+52',
            label: 'México',
            whatsapp: '+521'
        },
        {
            sms: '+1',
            label: 'Estados Unidos',
            whatsapp: '+11'
        }
    ]

    useEffect(() => {
        if(!data) {
            handleGetData(metaData)
            // console.log('get data', data)
        }
        // console.log(data)
    }, [])
    
    const handleOpen = () => {
        setOpen(!open);
        setIsDelete(false);
        setHelper();
        setEdit();
        setMessage(null)
    }

    const handleGetData = (newMetaData, isMerge = false) => {
        // console.log('new meta', newMetaData)
        let response = getAffiliates(newMetaData);
        response
            .then(async res => {
                setData(null)
                setList(null)
                // console.log(res)
                let response = res?.content
                let rows = await createRow(response)
                if(isMerge) {
                    rows = data?.concat(rows)
                }
                setMetaData({...metaData, ...newMetaData, ...res?.metadata, query: null})
                setData(rows)
                setList(response)
                setMessageFilter(null)
            })
    }

    const handleCode = (e) => {
        setHelper({message: 'El código debe ser unico para cada afiliado.'})
        // console.log(e.target.value)
        let value = e?.target?.value;
        if(value?.length >= 5) {
            let response = getByCode(value)
            response
                .then(res => {
                    // console.log(res)
                    if(res?.code) {
                        setHelper({message: 'Ya existe un afiliado con este código.', error: true})
                    } else {
                        setHelper()
                    }
                })
        } else {
            setHelper({message: 'El código debe tener al menos 5 digitos.', error: true})
        }
    }

    const handleSaveAfiliate = (e) => {
        e.preventDefault();
        setLoad(true)
        let form = new FormData(e.target);
        let values = Object.fromEntries(form.entries());
        // console.log(values)
        let newData = ['name', 'code', 'email', 'phone'].map( item => form[item]?.value);
        if(values?.code) {
            values['codigoPais'] = countryCodes[country][via];
            values['via'] = via;
            let response = postAffiliates(values);
            // console.log(response)
            response
                .then(res => {
                    // console.log(res)
                    setLoad(false)
                    setMessage({status: 200, message: "Nuevo afiliado guardado."})
                }).catch( err => {
                    setLoad(false)
                    setMessage({status: 500,message: "Hubo un problema, intenta de nuevo."})
                })
        } else {
            setHelper({message: 'El código no puede estar vacio', error: true})
            setLoad(false)
        }
    }

    const handleEditAffiliate = (e) => {
        e.preventDefault();
        setLoad(true)
        let form = new FormData(e.target);
        let values = Object.fromEntries(form.entries());
        // console.log(values)
        // let newData = ['name', 'code', 'email', 'phone'].map( item => form[item]?.value);
        if(values?.code) {
            values['codigoPais'] = countryCodes[country][via];
            values['via'] = via;
            // console.log(values)
            let response = putAffiliates({...values, enable: values?.enable === 'on'});
            response
                .then(res => {
                    setLoad(false)
                    setMessage({status: 200, message: "Afiliado actualizado."})
                })
                .catch( err => {
                    setLoad(false)
                    setMessage({status: 500,message: "Hubo un problema, intenta de nuevo."})
                })
        } else {
            setHelper({message: 'El código no puede estar vacio', error: true})
            setLoad(false)
        }
        
    }

    const handleDeleteAffiliate = () => {
        // console.log(edit)
        let response = deleteAffiliates(edit?._id)
        response.then( res => {
            // console.log(res)
            setIsDelete(false)
            setOpen();
            handleGetData(metaData);
        }).catch( err => {
            // console.log(err)
            setIsDelete(false)
        })
    }

    const handleSearch = (e) => {
        let search = metaData?.query;
        // console.log(search)
        if(search) {
            let response = getBySearch(search, metaData);
            response
                .then( async res => {
                    // console.log(res)
                    if(res?.content?.length > 0) {
                        setData(null)
                        setList(null)
                        let rows = await createRow(res?.content)
                        setMetaData({...metaData, ...res?.metadata})
                        setData(rows)
                        setList(res?.content)
                        setMessageFilter({status: 200, message: `${res?.content?.length} resultados para: ${search}`})
                    } else {
                        setMessageFilter({status: 400, message: `No hubo resultados para: ${search}`})
                    }
                })
                .catch( err => {
                    setMessageFilter({status: 500, message: "Hubo un error en la consulta, vuelve a intentarlo."})
                })
        }
    }

    const handleQuerySearch = (e) => {
        let query = e?.target?.value;
        setMetaData({...metaData, query: query})
        setMessage(null)
    }

    const componentAction = (row, i) => {
        return (
            <IconButton
                onClick={() => {
                    handleOpen();
                    setEdit(list[i]);
                }}
            ><EditIcon /></IconButton>
        )
    }

    const handleCountryCode = (e) => {
        setCountry(e?.target?.value)
    }

    const handlePaginator = (newMetaData) => {
        // console.log('New meta', newMetaData)
        // let metaDataFix = {...metaData, from: metaData?.size * metaData?.page, page: metaData?.page + 1}
        let metaDataFix = {...metaData, ...newMetaData}
        // console.log(metaDataFix)
        if(metaData?.total >= data?.length) {
            handleGetData(metaDataFix, true)
        }
    }
    
    // console.log(data)
    const createRow = async (data) => {
        // console.log(data)
        let rows = await data?.map( (row, index) => {
            // console.log('row: ', row)
            return {
                edit: <IconButton
                    onClick={() => {
                        handleOpen();
                        setEdit(row);
                    }}
                ><EditIcon /></IconButton>,
                code: <Tooltip placement="top" title={`Click para copiar: https://mimejorhipoteca.com/precalificar?ref=${row?.code}`} ><Button size="small" onClick={() => copyToClipboard(row?.code)} underline="none" sx={{textTransform: "lowercase"}} color="inherit"><b>{row?.code}</b></Button></Tooltip>,
                // code: row?.code,
                name: row?.name,
                email: row?.email,
                phone: row?.phone
            }
        })
        return rows;
    }

    const copyToClipboard = async (code) => {
        if(navigator) {
            await navigator?.clipboard?.writeText(`https://mimejorhipoteca.com/precalificar?ref=${code}`)
        }
    }

    return (
        <Grid
            container
            item
            spacing={2}
            pb={3}
            xs={12}
            md={10}
        >
            <Grid
                xs={12}
                item
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2
                }}
            >
                {
                    data
                    ?
                        open
                        ?
                            <Box
                                component="form"
                                onSubmit={edit ? handleEditAffiliate : handleSaveAfiliate}
                                sx={{
                                    display: 'contents'
                                }}
                            >
                                <Typography component="h1" variant="h5">{!edit ? 'Agregar nuevo' : 'Editar '} afiliado</Typography>
                                {
                                    edit && 
                                    <input
                                        name="_id"
                                        value={edit?._id}
                                        type="hidden"
                                    />
                                }
                                <TextField
                                    label="Código"
                                    variant="filled"
                                    fullWidth
                                    name="code"
                                    required
                                    focused
                                    defaultValue={edit?.code}
                                    // onChange={handleCode}
                                    onBlur={handleCode}
                                    helperText={helper?.message}
                                    error={helper?.error}
                                />

                                <Alert severity="info">Código de usuario debe ser en minusculas, minimo cinco caracteres y sin espacios, ejemplos: "mi-mejor-hipoteca", "mi_mejor_hipoteca" o "mimejorhipoteca".</Alert>

                                <TextField
                                    label="Nombre"
                                    variant="filled"
                                    fullWidth
                                    name="name"
                                    required
                                    defaultValue={edit?.name}
                                    // onChange={handleUser}
                                    // helperText={error}
                                    // error={error != null}
                                /> 

                                <FormControl variant="filled" fullWidth>
                                    <InputLabel>Pais</InputLabel>
                                    <Select
                                        value={country}
                                        label="Pais"
                                        // name="country"
                                        onChange={handleCountryCode}
                                    >
                                        {
                                            countryCodes?.map( (cc, i) => {
                                                return(
                                                    <MenuItem key={i} value={i}>{cc?.label}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                
                                <TextField
                                    label="Celular"
                                    variant="filled"
                                    fullWidth
                                    name="phone"
                                    type="number"
                                    required
                                    defaultValue={edit?.phone}
                                    // onChange={handleUser}
                                    helperText="Ejemplo: 6461234567"
                                    // error={error != null}
                                /> 
                                
                                <TextField
                                    label="Correo"
                                    variant="filled"
                                    fullWidth
                                    name="email"
                                    required
                                    type="email"
                                    defaultValue={edit?.email}
                                    // onChange={handleUser}
                                    // helperText={error}
                                    // error={error != null}
                                /> 

                                {
                                    edit && 
                                    <>
                                        <FormGroup>
                                            <FormControlLabel control={<Switch type="checkbox" name="enable" defaultChecked={edit?.enable} />} label="Activar usuario" />
                                        </FormGroup>
                                        <Alert severity="info">Esta opción {edit?.enable ? "desactivara" : "activara"} las notificaciones de nuevos clientes al usuario {edit?.name}</Alert>
                                    </>
                                }

                                <Box
                                    sx={{
                                        display: 'flex',
                                        columnGap: 2,
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    {
                                        edit 
                                        ?
                                            isDelete
                                            ?
                                                <Button
                                                    onClick={handleDeleteAffiliate}
                                                    disabled={load}
                                                    color="error"
                                                >¿Seguro que quieres borrar?</Button>
                                            :
                                                <Button
                                                    onClick={() => setIsDelete(true)}
                                                    disabled={load}
                                                    color="secondary"
                                                >Borrar</Button>
                                        : null
                                    }
                                    <Button
                                        onClick={handleOpen}
                                        disabled={load}
                                        color="secondary"
                                    >Regresar</Button>

                                    <Button
                                        type="submit"
                                        // onClick={handleSaveAfiliate}
                                        variant="contained"
                                        sx={{
                                            color: '#fff'
                                        }}
                                        disabled={load || helper?.error}
                                    >Guardar { load && <CircularProgress sx={{ml: 1}} size={20} color="inherit" /> }</Button>
                                    
                                </Box>

                                {
                                    message &&
                                    <Alert severity={message?.status == 200 ? "success" : "error"}>{message?.message}</Alert>
                                }

                            </Box>
                        :   
                        <>
                            <Typography
                                variant="h6"
                                component="h6"
                            >
                                Afiliados
                            </Typography>

                            <Grid 
                                spacing={1} 
                                container
                            >
                                <Grid item xs={12} md={10}>
                                    <TextField
                                        label="Buscar por código o correo."
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleQuerySearch}
                                        // helperText={error}
                                        // error={error != null}
                                        sx={{
                                            background: '#fff'
                                        }}
                                    /> 
                                </Grid>
                                <Grid item md={2}>
                                    <Button 
                                        size="large" 
                                        variant="contained" 
                                        color="secondary"
                                        sx={{color: '#fff', height: '100%', width: '100%'}} 
                                        disabled={!metaData?.query} 
                                        onClick={handleSearch}
                                        startIcon={<FilterAlt />}
                                    >Buscar</Button>
                                </Grid>
                                <Grid item>
                                    {
                                        messageFilter &&
                                        <Alert severity={messageFilter?.status == 200 ? "success" : messageFilter?.status == 500 ? "error" : 'warning'}>{messageFilter?.message}</Alert>
                                    }
                                </Grid>
                            </Grid>

                            <Typography variant="caption"><b>Link de afiliado, ejemplo: </b>https://mimejorhipoteca.com?ref=micodigo </Typography>

                            <Table 
                                title={`Resultados: ${data?.length}`}
                                list={data} 
                                rowActionLabel="Editar"
                                // rowAction={componentAction}
                                paginator={handlePaginator}
                                metaData={metaData}
                                index={true}
                                action={
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 2
                                        }}
                                    >
                                        <IconButton
                                            onClick={() => handleGetData({from: 0, size: 15, page: 0})}
                                        ><CachedIcon /></IconButton>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={handleOpen}
                                        >Nuevo afiliado</Button>
                                    </Box>
                                }
                                fields={fields}
                                responsiveFields={[]}
                            />
                        </>
                    : 
                        <Skeleton 
                            height={300} 
                            sx={{
                                transform: "none"
                            }}
                        />
                }
            </Grid>
        </Grid>
    ) 
}

export default Afiliados;
