import React, { useState, useEffect } from "react";
// import { Link } from "gatsby"
import {getAuditorias} from "../../services/getAuditorias";

import moment, { locale } from 'moment';
import 'moment/locale/es'

import Table from "./table";

import { 
    Box,
    Button, 
    IconButton, 
    CircularProgress, 
    Grid,
    Skeleton,
    TextField,
    Typography,
    Switch,
    FormGroup,
    FormControl, 
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Alert,
    Tooltip
} from "@mui/material";

import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
 
const Auditorias = () => {
    const [data, setData] = useState(null);
    const [size, setSize] = useState(10);
    const fields = [
      {
          id: "folio",
          label: "Folio"
      },
      {
          id: "fecha",
          label: "Fecha"
      },
      {
        id: "hora",
        label: "Hora"
      },
      {
        id: "nombre",
        label: "Nombre"
      },
      {
        id: "rfc",
        label: "RFC"
      },
      {
        id: "calle",
        label: "Calle"
      },
      {
        id: "colonia",
        label: "Colonia"
      },
      {
        id: "ciudad",
        label: "Ciudad"
      },
      {
        id: "estado",
        label: "Estado"
      },
      {
        id: "tipoConsulta",
        label: "Tipo consulta"
      },
      {
        id: "usuario",
        label: "Usuario"
      },
      {
        id: "fechaAprobacion",
        label: "Fecha aprobacion"
      },
      {
        id: "horaAprobacion",
        label: "Hora aprobacion"
      },
      {
        id: "ingresoNip",
        label: "Ingreso Nip"
      },
      {
        id: "respuestaLeyenda",
        label: "Respuesta leyenda"
      },
      {
        id: "aceptoTerminos",
        label: "Acepto terminos"
      },
    ]

    const states = {
      "Aguascalientes": "AGS",
      "Baja California": "BC",
      "Baja California Sur": "BCS",
      "Campeche": "CAMP",
      "Chiapas": "CHIS",
      "Chihuahua": "CHIH",
      "Coahuila de Zaragoza": "COAH",
      "Colima": "COL",
      "Ciudad de México": "CDMX",
      "Distrito Federal": "DF",
      "Durango": "DGO",
      "Guanajuato": "GTO",
      "Guerrero": "GRO",
      "Hidalgo": "HGO",
      "Jalisco": "JAL",
      "México": "MEX",
      "Michoacán de Ocampo": "MICH",
      "Morelos": "MOR",
      "Nayarit": "NAY",
      "Nuevo León": "NL",
      "Oaxaca": "OAX",
      "Puebla": "PUE",
      "Querétaro": "QRO",
      "Quintana Roo": "QROO",
      "San Luis Potosí": "SLP",
      "Sinaloa": "SIN",
      "Sonora": "SON",
      "Tabasco": "TAB",
      "Tamaulipas": "TAMP",
      "Tlaxcala": "TLAX",
      "Veracruz de Ignacio de la Llave": "VER",
      "Yucatán": "YUC",
      "Zacatecas": "ZAC",
    }

    useEffect(() => {
        if(!data) {
            handleGetData()
            // console.log('get data', data)
        }
        // console.log(data)
    }, [])

    useEffect(() => {
      if(size != data?.length) {
        handleGetData()
      }
    }, [size])

    const handleGetData = () => {
        // console.log('new meta', newMetaData)
        let response = getAuditorias(size);
        response
            .then(async res => {
                setData(null)
                console.log(res)
                let response = res
                let rows = await createRow(response)
                setData(rows)
            })
    }
    
    const createRow = async (data) => {
        // console.log(data)
        let rows = await data?.map( (row, index) => {
            // console.log('row: ', row)
            return {
                folio: row?.consultas[row?.consultas?.length - 1]?.folioConsulta,
                fecha: moment(row?.consultas[row?.consultas?.length - 1]?.fechaConsulta).format('YYYY/MM/DD'),
                hora: moment(row?.consultas[row?.consultas?.length - 1]?.fechaConsulta).format('HH:mm:ss'),
                nombre: `${row?.persona?.primerNombre} ${row?.persona?.segundoNombre} ${row?.persona?.apellidoPaterno} ${row?.persona?.apellidoMaterno}`,
                rfc: row?.persona?.RFC,
                calle: row?.domicilios?.calle,
                colonia: row?.domicilios?.colonia,
                ciudad: row?.domicilios?.ciudad || row?.domicilios?.municipio,
                estado: states[row?.domicilios?.estado],
                tipoConsulta: "PF",
                usuario: "JGC8672MMH",
                fechaAprobacion: moment(row?.consultas[row?.consultas?.length - 1]?.fechaConsulta).format('YYYY/MM/DD'),
                horaAprobacion: moment(row?.consultas[row?.consultas?.length - 1]?.fechaConsulta).subtract(30, 'seconds').format('HH:mm:ss'),
                ingresoNip: "SI",
                respuestaLeyenda: "SI",
                aceptoTerminos: "SI"
            }
        })
        return rows;
    }

    return (
        <Grid
            container
            item
            spacing={2}
            pb={3}
            xs={12}
            md={10}
        >
            <Grid
                xs={12}
                item
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2
                }}
            >
                {
                    data
                    ?
                      <>
                          <Typography
                              variant="h6"
                              component="h6"
                          >
                              Auditorias
                          </Typography>

                          <Table 
                              title={`Resultados: ${data?.length}`}
                              list={data} 
                              index={false}
                              fields={fields}
                              responsiveFields={[]}
                              hiddenButton={true}
                              action={
                                <IconButton
                                  onClick={() => setSize(size + 10)}
                                ><PlaylistAddIcon /></IconButton>
                            }
                          />

                      </>
                    : 
                        <Skeleton 
                            height={300} 
                            sx={{
                                transform: "none"
                            }}
                        />
                }
            </Grid>
        </Grid>
    ) 
}

export default Auditorias;
