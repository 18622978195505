import axios from "axios";

export const getClients = async (metaData) => {
    
    let token = '123456';

    let config = {
      headers: {
        'x-access-token': token
      }
    }

    let response = await axios.get(
        `/api/v1/credito?from=${metaData?.from}&size=${metaData?.size}`,
        config
    )

    let result = response?.data

    return result;
}

export const getBySearch = async (data, metaData) => {
  let token = '123456';

  let config = {
    headers: {
      'x-access-token': token
    }
  }

  let response = await axios.get(
      `/api/v1/credito/filter/${data}?from=${metaData?.from}&size=${metaData?.size}`,
      config
  )

  let result = response?.data;

  return result;
}

export const postAuditoria = async (data) => {
  let token = '123456';

  let config = {
    headers: {
      'x-access-token': token
    }
  }

  let response = await axios.post(
      `/api/v1/credito/auth-nip`,
      data,
      config
  )

  let result = response?.data;

  return result;
  
}